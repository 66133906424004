<template>
	<div style="position: relative;">
		<!-- <HeadTop title="密码登录" /> -->
		<!-- <div style="display: flex;align-items: center;justify-content: space-between;height: 50px;background-color: ;">
			<div>
				<img style="width:20px;" src="../../../assets/icon/Basics/GoBack.png" alt="" />
				
			</div>
			<div>
				密码登录
				
			</div>
			<div>
				
				
			</div>
		</div> -->

		<div class="img">
			<img style="width: 100%;" src="../Img/Register.png" alt="" />
		</div>
		<div class="" style="position: absolute;top: 160px;left:50%;margin-left:-25px">
			<img style="width:50rpx;" src="../Img/accunt.png" alt="" />
		</div>
		<div style="height: 100px;line-height: 100px;font-size: 25px;" @click="toweichat">
			 微信一键登录
		
		</div>
		<div class="PassLoginBox">
			<router-link to="/register">
				<span>切换其他登录</span>
			</router-link>
			<!--  -->
			<div class="PassLoginBoxMain">
				<!--  <div class="buBottom">
                     <img src="../assets/img/wechatlogin.png" alt="" /> 微信一键登录
                </div> -->


				<!-- <div class="PassLoginToOther">
                    <router-link to="/register">
                        <span>切换其他登录</span>
                    </router-link>
                </div> -->
			</div>
		</div>
		<!-- <PassLoginFooter /> -->

		<!-- <Loading v-if="loadingShow"/> -->
	</div>
</template>
<script>
	// import {
	// 	Login
	// } from '../../api/user/user.js'
	export default {
		data() {
			return {
				// isCollapse: false
			}
		},
		methods: {
			toweichat() {
				//判断是否是微信浏览器
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				); {
					//判断是否是微信浏览器
					var ua = navigator.userAgent.toLowerCase();
					if (ua.match(/MicroMessenger/i) == "micromessenger") {
			  	//获取当前页面的地址
						let local = window.location.href;
						//调接微信官方生成授权登录的url appid必须填写
						let url =
							"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect&redirect_uri=" +
							encodeURIComponent(local) +
							"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
						//这一步是跳转这上方定义的url
						window.location.href = url;
					} else {
						// this.$message.fail("请在微信浏览器下使用此功能");
					}
				}
			},
			GetParam(url, code) {
				url = url + "";
				console.log(code)
				let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
				let reg = eval(regstr);
				//eval可以将 regstr字符串转换为 正则表达式
				let result = url.match(reg);
				if (result && result[2]) {
					return result[2];
				}
			},


		},
		created() {
			let code = this.GetParam(window.location.href, "code");
			if (code != undefined) {
				console.log(code)
				//这里可以进行后续操作 已经能出现code了 一般是调用接口发送给后台这个code码以换取openid或者token码
			}
		},
		mounted() {

		}
	}
</script>
<style lang="less">
	@import "KeyLogin";
</style>
